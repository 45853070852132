import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';

/*
 * This is a convention file used by
 * @stubhub/speedy-bootstrap and @stubhub/bullet-scripts
 * DO NOT MOVE IT
 */

/* istanbul ignore file : bullet convention file */
/**
 * This is a Bullet App convention file.
 * You can modify at below `TODO` spots to add more app routes
 *
 *
 * Please only export a default method, e.g. called `getRoutes`,
 * which returns an array of `Route` (from react-router-dom v4.x)
 */
import appRoutes from './apps/discovery-routes';
import eventRoutes from './apps/event-routes';
import NotFoundModule from './modules/react-404-module';
import {UNSUPPORTED_ROUTES} from './utils/routesRegex';

const NotFoundRoute = ({location, staticContext}) => {
  if (staticContext) {
    staticContext.statusCode = 404;
  }

  if (typeof window !== 'undefined' && window.TrackJS) {
    const {pathname} = location || {};
    window.TrackJS.track(`NotFound page: ${pathname}`);
  }

  return <NotFoundModule />;
};

NotFoundRoute.propTypes = {
  staticContext: PropTypes.object,
  location: PropTypes.object,
};

export default function getRoutes({
  currentLocale,
  defaultLocale,
  shstoreId,
  features,
  device,
  logger,
  addFallbackRoutes,
  currentPath,
}) {
  const REGX_EVENT_PAGE = /\/event\/\d*/i;
  const unsupportedRoutesRegex = new RegExp(UNSUPPORTED_ROUTES, 'i');

  if (addFallbackRoutes) {
    if (__CLIENT__) {
      return [<Route key="notFound" path={currentPath || window.location.pathname} render={NotFoundRoute} />];
    }

    return [<Route key="notFound" path={currentPath} render={NotFoundRoute} />];
  }

  if (__CLIENT__) {
    if (REGX_EVENT_PAGE.test(window.location.pathname)) {
      return [...eventRoutes({currentLocale, defaultLocale, shstoreId, features, device, logger})];
    }

    return [
      ...appRoutes({currentLocale, defaultLocale}),
      <Route key="notFound" path={unsupportedRoutesRegex} render={NotFoundRoute} />,
    ];
  }

  return [
    ...appRoutes({currentLocale, defaultLocale}),
    ...eventRoutes({currentLocale, defaultLocale, shstoreId, features, device, logger}),
    <Route key="notFound" path={unsupportedRoutesRegex} render={NotFoundRoute} />,
  ];
}
