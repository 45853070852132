import {useEffect} from 'react';

export const useTimeout = (func, milliSeconds) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      func();
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [func, milliSeconds]);
};
