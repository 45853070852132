import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {HTTP_CODES} from '@stubhub/general-utils';
import {publish} from '@stubhub/pubsub-utils';
// eslint-disable-next-line import/no-named-as-default
import ErrorPanel from '@stubhub/react-error-panel';
import {formatURLDomain} from '@stubhub/sh-url-utils';

import {NOT_FOUND_EVENTS} from '../../tracking/track-enum';

const NotFoundModule = (_, {globalRegistry}) => {
  useEffect(() => {
    publish(NOT_FOUND_EVENTS.LOADED);
  }, []);

  const defaultLocale = globalRegistry?.getDefaultLocale();
  const currentLocale = globalRegistry?.getCurrentLocale();

  return (
    <ErrorPanel
      className="not-found-module"
      statusCode={HTTP_CODES.RESOURCE_NOT_FOUND}
      redirectUrl={formatURLDomain({href: '/', defaultLocale, currentLocale})}
    />
  );
};

NotFoundModule.contextTypes = {
  globalRegistry: PropTypes.object,
};

export default NotFoundModule;
