/*
 * This is a convention file used by
 * @stubhub/bullet-scripts
 * DO NOT MOVE IT
 */

/* istanbul ignore file : bullet convention file */
import start from '@stubhub/speedy-bootstrap';

import {render} from './speedy.app.js';

start({render});
