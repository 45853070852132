import {shstoreIdMappings} from '@stubhub/general-utils';
import {getSelectedCurrencyCode} from '@stubhub/react-currency-exchange';
import getStoreCurrencyCode from '@stubhub/react-store-default-currency';
import {Controller} from '@stubhub/react-store-provider';

import {NAMESPACE as ENTITY_NAMESPACE} from '../apps/entity/controller';
import {entitySelector} from '../apps/entity/helpers/entity-selectors';
import {
  isAdvisoryCurrencyFFEnabledSelector,
  isAdvisoryCurrencyOptOutFFEnabledSelector,
} from '../store/advisoryCurrency/selectors';
import {
  daysToEventSelector,
  eventCountryCodeSelector,
  firstPerformerSelector,
  genreSelector,
  inventoryMarketSelector,
  eventSelector,
} from '../store/event/selectors';
import {currentLocaleSelector, storeIdSelector} from '../store/gsConfig/selectors';
import {searchbarSelector} from '../store/searchBar/selectors';
import {userIpCoordsSelector, userIpLocationSelector} from '../store/user/selectors';

import {trackEventGCPAncestorsSelector, trackEntityGCPAncestorsSelector, trackingQuantitySelector} from './selectors';

const NAMESPACE = 'tracking';

const controller = new Controller({
  namespace: NAMESPACE,
  mapStateToProps: (state) => {
    const storeId = storeIdSelector(state);
    const {site: storeCountry} = shstoreIdMappings[storeId] || {};
    const entity = entitySelector(state[ENTITY_NAMESPACE] || {});
    const searchbar = searchbarSelector(state);
    const isAdvisoryOptOutEnabled = isAdvisoryCurrencyOptOutFFEnabledSelector(state);
    let advisoryCurrency = getSelectedCurrencyCode();
    if (isAdvisoryOptOutEnabled && !advisoryCurrency) {
      advisoryCurrency = getStoreCurrencyCode(storeId);
    }
    const event = eventSelector(state);
    const {eventId, name, ancestors, eventStructureData, eventDateUTC, dynamicAttributes} = event;
    const {countryCode, city} = userIpLocationSelector(state);

    return {
      storeId,
      storeCountry,
      locale: currentLocaleSelector(state),
      isAdvisoryCurrencyEnabled: isAdvisoryCurrencyFFEnabledSelector(state),
      advisoryCurrency,
      entity,
      searchbar,
      eventId,
      name,
      ancestors,
      eventStructureData,
      eventDateUTC,
      dynamicAttributes,
      daysToEvent: daysToEventSelector(state),
      eventCountry: eventCountryCodeSelector(state),
      firstPerformer: firstPerformerSelector(state),
      genre: genreSelector(state),
      inventoryMarket: inventoryMarketSelector(state),
      userCountry: countryCode,
      userCity: city,
      userCoords: userIpCoordsSelector(state),
      eventGCP: trackEventGCPAncestorsSelector(state),
      entityGCP: trackEntityGCPAncestorsSelector(state),
      quantities: trackingQuantitySelector(state),
    };
  },
});

export default controller;
