import {createSelector} from 'reselect';

const userStateSelector = (state) => state?.user || {};
const userLocationSelector = createSelector(userStateSelector, (user) => user?.location || {});
const currentLocationSelector = createSelector(userStateSelector, (user) => user?.currentLocation || {});
const pointSelector = createSelector(userLocationSelector, (location) =>
  location ? `${location.latitude},${location.longitude}` : null,
);
const getGeoCityNameSelector = createSelector(userLocationSelector, (location) => location?.geoNameId || null);

const userIpLocationSelector = createSelector(userStateSelector, (userSelector) => userSelector.ipLocation || {});
const userIpCoordsSelector = createSelector(userIpLocationSelector, (userIpLocationSelector) =>
  userIpLocationSelector.latitude && userIpLocationSelector.longitude
    ? `${userIpLocationSelector.latitude},${userIpLocationSelector.longitude}`
    : '',
);

export {
  userLocationSelector,
  currentLocationSelector,
  pointSelector,
  getGeoCityNameSelector,
  userIpLocationSelector,
  userIpCoordsSelector,
};
