import {createSelector} from 'reselect';

import {entitySelector} from '../apps/entity/helpers/entity-selectors';
import {eventSelector} from '../store/event/selectors';
import {mergeGCPArrays} from '../utils/catalog';

const trackingGCPAncestors = (eventInfo) => {
  const {ancestors = {}, categories = [], performers = [], groupings = [], allPerformers} = eventInfo || {};
  const mergedCategories = mergeGCPArrays(ancestors?.categories, categories);
  const mergedPerformers = mergeGCPArrays(ancestors?.performers, allPerformers || performers);
  const mergedGroupings = mergeGCPArrays(ancestors?.groupings, groupings);

  return {categories: mergedCategories, performers: mergedPerformers, groupings: mergedGroupings};
};

const trackEventGCPAncestorsSelector = createSelector(eventSelector, (eventInfo) => {
  return trackingGCPAncestors(eventInfo);
});

const trackEntityGCPAncestorsSelector = createSelector(entitySelector, (entityInfo) => {
  return trackingGCPAncestors(entityInfo);
});

const trackingQuantitySelector = createSelector(
  eventSelector,
  (state) => state?.listings,
  (event, listings) => {
    if (listings && !listings.listingLoading) {
      const {totalListings, totalTickets} = listings;

      return {totalListings, totalTickets};
    }

    const {eventStructureData} = event;
    if (eventStructureData && Object.keys(eventStructureData).length) {
      const {ticketInfo: {totalListings, totalTickets} = {}} = eventStructureData;

      return {totalListings, totalTickets};
    }

    return {totalListings: undefined, totalTickets: undefined};
  }
);

export {trackEventGCPAncestorsSelector, trackEntityGCPAncestorsSelector, trackingQuantitySelector};
