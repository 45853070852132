import {useCallback, useEffect, useRef} from 'react';

import {useTimeout} from './useTimeout';

export const useExitIntent = (sensitivity = 20, timerMs = 1500, delayMs = 100) => {
  const handlers = useRef([]);
  const delayTimer = useRef(null);
  useTimeout(() => {
    if (typeof window !== 'undefined') {
      document.body.addEventListener('mouseleave', handleMouseLeave);
      document.body.addEventListener('mouseenter', handleMouseEnter);
    }
  }, timerMs);

  const handleMouseLeave = useCallback(
    (e) => {
      if (e.clientY > sensitivity) {
        return;
      }
      delayTimer.current = setTimeout(() => handlers.current.forEach((handler) => handler.handler()), delayMs);
    },
    [sensitivity, delayMs]
  );

  const handleMouseEnter = useCallback(() => {
    if (delayTimer) {
      clearTimeout(delayTimer);
      delayTimer.current = null;
    }
  }, [delayTimer]);

  const registerHandler = useCallback((handler) => {
    const handlerIndex = handlers.current.findIndex((registeredHandler) => registeredHandler.id === handler.id);
    const newHandler = {
      ...handler,
      context: handler?.context || [],
    };
    if (handlerIndex > -1) {
      handlers.current[handlerIndex] = newHandler;

      return;
    }
    handlers.current.push(newHandler);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      return () => {
        document.body.removeEventListener('mouseleave', handleMouseLeave);
        document.body.removeEventListener('mouseenter', handleMouseEnter);
      };
    }
  }, [handleMouseEnter, handleMouseLeave]);

  return {registerHandler, handlers: handlers.current};
};
