import {NONE_CURRENCY_VALUE} from '@stubhub/currency-utils';
import {toBase64} from '@stubhub/general-utils';
import {gcpTree, getGeoId, getGeoTree, getParentGCPId} from '@stubhub/tracking-utils';

import {MILLIS_OF_SECOND} from '../utils/timeConsts';

import {TICKET_EVENTS} from './track-enum';

/**
 * Sends a scren_view track with this data:
 *
 * dataLayer.push({
 *  “event”: “screen_view”, // a default GA4 event
 *  "screen_name": "home", // a literal
 *  "store_id": “”, // the SH Store ID as string (for compatibility with OB & TB)
 *  "store_country": "", // the two-letter ISO code of the country, such as “ES”
 *  "visit_platform": "", // one of “sh-web”, “sh-ios”, “sh-android”
 *  "visit_locale": "", // the locale of the current pageview, such as “es-ES”
 *  "visit_advisory_currency": "", // one of “OFF”, “NONE”, “EUR”, “USD”, etc.
 *  "visit_guid": "", // the current user's GUID if logged-in, null otherwise
 *  "visit_country": "", // the two letter ISO code of the visitor’s current country
 *  "visit_city": "", // the english name of the visitor’s current city
 *  "visit_coords": "", // the user’s current coords in the form "43.262985,-2.935013”
 *});
 *
 * @param {object} track the track object with at least screen_view method
 * @param {object} data the data sent to track
 */
const screenView = (
  track,
  {
    screenName,
    storeId,
    storeCountry,
    locale,
    isAdvisoryCurrencyEnabled,
    advisoryCurrency,
    userGUID,
    userCountry,
    userCity,
    userCoords,
  } = {}
) => {
  track.screen_view({
    screen_name: screenName,
    store_id: storeId?.toString() || null,
    store_country: storeCountry || null,
    visit_platform: 'sh-web',
    visit_locale: locale || null,
    visit_advisory_currency: isAdvisoryCurrencyEnabled ? advisoryCurrency || NONE_CURRENCY_VALUE : 'OFF',
    visit_guid: userGUID || null,
    visit_country: userCountry || null,
    visit_city: userCity || null,
    visit_coords: userCoords || null,
  });
};

const getEntityItems = ({entityType, id, name, ancestors = {}, images = []}, entityGCP = {}) => {
  const item = {
    item_name: `sh-${entityType}-${id}`,
    item_id: id?.toString() || null,
    entity_name: toBase64(name),
    parent_gcp_id: getParentGCPId(ancestors)?.toString() || null,
    gcp_tree: gcpTree(entityGCP),
    geo_id: getGeoId(ancestors)?.toString() || null,
    geo_tree: getGeoTree(ancestors),
    picture_url: images.length > 0 ? images[0].urlSsl || images[0].url : null,
  };

  return [item];
};

const getViewItemSharedParameters = ({
  firstPerformer,
  eventStructureData = {},
  eventDateUTC,
  dynamicAttributes = [],
  ancestors = {},
  eventGCP,
}) => {
  const {id: firstPerformerId} = firstPerformer;
  const ancestorsTree = gcpTree(eventGCP);
  const pictureUrl = eventStructureData.imageUrl || null;
  const eventDateTs = eventDateUTC ? new Date(eventDateUTC).getTime() / MILLIS_OF_SECOND : null;
  const eventOnSaleDate =
    Object.keys(dynamicAttributes).length > 0
      ? dynamicAttributes.find((attr) => attr.name === 'event_schedule_date')
      : null;
  const eventOnSaleDateTs = eventOnSaleDate?.value
    ? new Date(eventOnSaleDate.value).getTime() / MILLIS_OF_SECOND
    : null;
  const geoId = getGeoId(ancestors);
  const geoTree = getGeoTree(ancestors);

  return {firstPerformerId, ancestorsTree, pictureUrl, eventDateTs, eventOnSaleDateTs, geoId, geoTree};
};

const entityViewItem = (track, entity = {}, entityGCP = {}) => {
  track.view_item({
    item_list_id: `browse_${entity.entityType}`,
    items: getEntityItems(entity, entityGCP),
  });
};

const entityViewItemList = (track, {itemListId, filterKeywords, performerListQty, eventListQty, venueListQty} = {}) => {
  track.view_item_list({
    item_list_id: itemListId || null,
    filter_keywords: filterKeywords ? toBase64(filterKeywords) : null,
    performer_list_qty: performerListQty ?? 0,
    event_list_qty: eventListQty ?? 0,
    venue_list_qty: venueListQty ?? 0,
  });
};

const getUserGUID = (cookies) => (cookies && cookies.get && cookies.get('session_userGUID')) || null;

const entityScreenView = (track, props, cookies, entityType) => {
  const {
    storeId,
    storeCountry,
    locale,
    isAdvisoryCurrencyEnabled,
    advisoryCurrency,
    userCountry,
    userCity,
    userCoords,
  } = props;
  const userGUID = getUserGUID(cookies);
  const screenName = `browse_${entityType}`;
  screenView(track, {
    screenName,
    storeId,
    storeCountry,
    locale,
    isAdvisoryCurrencyEnabled,
    advisoryCurrency,
    userGUID,
    userCountry,
    userCity,
    userCoords,
  });
};

const listingViewItem = (
  track,
  {
    listingId,
    price,
    currency,
    quantity,
    eventId,
    days_to_event,
    genre,
    inventory_market,
    firstPerformerId,
    gcp_tree,
    country_code,
    geo_id,
    geo_tree,
    picture_url,
    event_date_ts,
    event_onsale_ts,
    eventName,
  }
) => {
  const eventNameTransformed = eventName ? toBase64(eventName) : null;
  track.view_item({
    item_list_id: 'event_listings',
    items: [
      {
        item_name: `sh-listing-${listingId}`,
        item_id: `${listingId}`,
        price,
        currency,
        quantity,
        event_id_unique: `sh-event-${eventId}`, // Where XXX is the event ID
        event_id: `${eventId}`, // The event ID
        days_to_event, // Where 1.50 would mean 36 hours
        genre,
        inventory_market,
        parent_gcp_id: firstPerformerId ? firstPerformerId.toString() : null, // The item’s parent category ID as a string
        gcp_tree, // Categories + grouping + performerId
        country_code, // The item’s country as a two-letter ISO code
        event_date_ts, // The timestamp of the event date, in seconds
        event_onsale_ts, // The timestamp of the event's onsale, in seconds
        picture_url, // The URL  to the event's picture
        geo_id, // The item's parent location id as a string
        geo_tree, // The item's tree as string
        event_name: eventNameTransformed, // In English, defaulting to the local language, in base64
        event_name_local: eventNameTransformed, // In the local language, defaulting to en, in base64
      },
    ],
  });
};

const eventViewItem = (
  track,
  {
    eventId,
    days_to_event,
    name,
    genre,
    inventory_market,
    parent_gcp_id,
    gcp_tree,
    country_code,
    geo_id,
    geo_tree,
    picture_url,
    event_date_ts,
    event_onsale_ts,
    listing_qty,
    ticket_qty,
  }
) => {
  track.view_item({
    item_list_id: 'event',
    items: [
      {
        item_name: `sh-event-${eventId}`,
        item_id: `${eventId}`,
        days_to_event,
        event_name: name ? toBase64(name) : null,
        genre,
        inventory_market,
        parent_gcp_id: parent_gcp_id ? parent_gcp_id.toString() : null,
        gcp_tree,
        country_code,
        event_date_ts, // The timestamp of the event date, in seconds
        event_onsale_ts, // The timestamp of the event's onsale, in seconds
        picture_url, // The URL  to the event's picture
        geo_id, // The item's parent location id as a string
        geo_tree, // The item's tree as string
        listing_qty,
        ticket_qty,
      },
    ],
  });
};

const noFullfilmentError = (track) => {
  track.custom_event({
    event: 'error_platform',
    error_name: TICKET_EVENTS.FULLFILMENT_ERROR,
    details: 'Fulfillment info of Listing not found (BYO)',
  });
};

const subscribeNewsletter = (track, email) => {
  track.custom_event({
    event: 'subscribe_to_newsletter',
    email: toBase64(email),
  });
};

const abandonViewport = (
  track,
  {storeId, storeCountry, locale, isAdvisoryCurrencyEnabled, advisoryCurrency, userGUID}
) => {
  track.custom_event({
    event: 'abandon_viewport', // A custom event
    store_id: storeId?.toString() || null, // The SH Store ID as string (for compatibility with OB & TB)
    store_country: storeCountry || null, // The two-letter ISO code of the country, such as “ES”
    visit_platform: 'sh-web', // One of “sh-web”, “sh-ios”, “sh-android”
    visit_locale: locale || null, // The locale of the current pageview, such as “es-ES”
    visit_advisory_currency: isAdvisoryCurrencyEnabled ? advisoryCurrency || NONE_CURRENCY_VALUE : 'OFF', // One of “OFF”, “NONE”, “EUR”, “USD”, etc.
    visit_guid: userGUID || null, // The current user's GUID if logged-in, null otherwise
  });
};

const chatbot = (track, {storeId, storeCountry, locale, status}) => {
  track.custom_event({
    event: 'use_feature',
    feature_name: `chatbot_${status}`,
    feature_details: null,
    store_id: storeId?.toString() || null,
    store_country: storeCountry || null,
    visit_platform: 'sh-web',
    visit_locale: locale || null,
  });
};

export {
  screenView,
  entityViewItem,
  entityViewItemList,
  entityScreenView,
  getUserGUID,
  listingViewItem,
  eventViewItem,
  getViewItemSharedParameters,
  subscribeNewsletter,
  abandonViewport,
  noFullfilmentError,
  chatbot,
};
