import {createSelector} from 'reselect';

import FEATURE_FLAGS from '../../../utils/featureFlags';
import {featureFlagsSelector} from '../selectors';

const showImportantInfoModalSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.event.importantInfoModal]
);

const showApplePaySelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.payment.applePay]
);

const isPayPalPayLaterEnabledSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.common.paypal.payLater]
);

const isCBTModuleEnabledSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.common.cbtModule.show]
);

const isRedirectModalEnabledSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.common.redirectModal.show]
);

const hideCbtEventsSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.common.hideCbtEvents]
);

const showByoMapSelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.byo.map.show]
);

const showPopularInCountrySelector = createSelector(
  featureFlagsSelector,
  (features = {}) => !!features[FEATURE_FLAGS.browse.popularInCountry]
);

export {
  showImportantInfoModalSelector,
  showApplePaySelector,
  isPayPalPayLaterEnabledSelector,
  isCBTModuleEnabledSelector,
  isRedirectModalEnabledSelector,
  hideCbtEventsSelector,
  showByoMapSelector,
  showPopularInCountrySelector,
};
