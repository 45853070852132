import {start} from '@stubhub/speedy-react-renderer';

// Option for @stubhub/speedy-react-renderer to define a global Layout
import Layout from './layout';
// Option for @stubhub/speedy-react-renderer to obtain routes definition
import getRoutes from './routes';

export function render() {
  return start({
    layout: Layout,
    getRoutes,
    isFallbackApp: true,
  });
}
