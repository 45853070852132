/* istanbul ignore file : bullet convention file */
import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'SEO.title',
    defaultMessage: 'Buy sports, concert and theater tickets on StubHub!',
  },
  description: {
    id: 'SEO.description',
    defaultMessage: 'Buy and sell sports tickets, concert tickets, theater tickets and Broadway tickets on StubHub!',
  },
});

export default messages;
