/* istanbul ignore file */

/**
 * This is a Bullet App convention file.
 * DON'T RENAME OR MOVE TO OTHER FOLDERS.
 *
 * Please only export a default method e.g. called `getRoutes`,
 * which returns an array of `Route` (from react-router-dom v4.x)
 */

import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import Spinner from '@stubhub/react-spinner';
import {parseUrl} from '@stubhub/sh-url-utils';

const EventRoyal = Loadable({
  loader: () => import(/* webpackChunkName: "event" */ './event-royal'),
  loading: Spinner,
});

const wildcardRouteIds = [':eventId'];

function eventRoyalSpeedy(routeIds) {
  const routes = routeIds.map((eventId, index) => {
    return (
      <Route
        path={`*/event/${eventId}`}
        key={`event${index}`}
        render={(props) => <EventRoyal eventId={eventId} {...props.match.params} {...props} />}
      />
    );
  });

  return routes;
}

export default function () {
  const routeIds = wildcardRouteIds;

  let eventIds;

  if (__CLIENT__) {
    const currentEventId = parseUrl(window.location.pathname).sh_entity_id;
    eventIds = [currentEventId];
  } else {
    eventIds = routeIds;
  }

  const routes = eventRoyalSpeedy(eventIds);

  return routes || [];
}
