import {isGroupingEntity, isPerformerEntity} from '@stubhub/entity-helper';

import {PERFORMER_IDS, GROUPING_IDS} from './constants';

/**
 * Configuration object containing arrays of banned IDs for different entity types.
 * @typedef {Object} BannedIdsConfig
 * @property {Array<number|string>} performer - Array of banned performer IDs.
 * @property {Array<number|string>} grouping - Array of banned grouping IDs.
 */
const BANNED_IDS_CONFIG = {
  performer: [PERFORMER_IDS.COMMUNITY_SHIELD, PERFORMER_IDS.EURO_2020],
  grouping: [
    GROUPING_IDS.CHAMPIONS_LEAGUE,
    GROUPING_IDS.EUROPA_LEAGUE,
    GROUPING_IDS.PREMIER_LEAGUE,
    GROUPING_IDS.ENGLAND_NATIONAL_FOOTBALL_LEAGUE,
  ],
};

/**
 * Array of allowed SEO IDs for specific performers.
 * @type {Array<number|string>}
 */
const ALLOWED_SEO_IDS_CONFIG = [PERFORMER_IDS.MANCHESTER_UNITED, PERFORMER_IDS.EVERTON, PERFORMER_IDS.MANCHESTER_CITY];

/**
 * Checks if a specific entity is banned checking a banned list of performer and groupings.
 * @param {Object} entity - The entity to check.
 * @param {string} entity.entityType - The type of the entity (e.g., 'performer', 'grouping').
 * @param {number|string} entity.id - The id of the entity.
 * @param {number|string} entity.parentGroupingId - The parent grouping id of the entity, if applicable.
 * @returns {boolean} - True if the entity is banned, false otherwise.
 */
export const isBannedEntity =
  (bannedIds) =>
  ({entityType, id, parentGroupingId}) => {
    if ((isPerformerEntity(entityType) || isGroupingEntity(entityType)) && parentGroupingId) {
      return bannedIds.grouping.some((bannedId) => parseInt(bannedId) === parseInt(parentGroupingId));
    }

    if (Array.isArray(bannedIds[entityType])) {
      return bannedIds[entityType].some((bannedId) => parseInt(bannedId) === parseInt(id));
    }

    return false;
  };

/**
 * Determines if an SEO entity should be hidden based on its type and ID.
 * @param {Object} params - The parameters object.
 * @param {string} params.entityType - The type of the entity.
 * @param {string|number} params.id - The ID of the entity.
 * @returns {boolean} - Returns true if the entity should be hidden, false otherwise.
 */
export const hideSEOEntity = ({entityType, id}) => {
  return !isPerformerEntity(entityType) || !ALLOWED_SEO_IDS_CONFIG.includes(parseInt(id));
};

export default isBannedEntity(BANNED_IDS_CONFIG);
