import pick from 'object.pick';

import {
  ENTITY_TYPE_PERFORMER,
  ENTITY_TYPE_EVENT,
  ENTITY_TYPE_GROUPING,
  ENTITY_TYPE_CATEGORY,
  ENTITY_TYPE_VENUE,
  ENTITY_TYPE_GEO,
} from '@stubhub/entity-helper';
import {get} from '@stubhub/rest-method';

const {CATALOG_CACHE_MAX_AGE} = require('./constants');

// This mapping is used to generate the entity type used in Catalog API URL path
const entityTypesPluralMapping = {
  [ENTITY_TYPE_EVENT]: 'events',
  [ENTITY_TYPE_PERFORMER]: 'performers',
  [ENTITY_TYPE_GEO]: 'geographies',
  [ENTITY_TYPE_CATEGORY]: 'categories',
  [ENTITY_TYPE_GROUPING]: 'groupings',
  [ENTITY_TYPE_VENUE]: 'venues',
};

/**
 * Method to Pluralize the Entity Type.
 * @param {String} entityType
 * @param {Boolean} useSearchCatalogAPI @TODO - `REMOVE_useSearchCatalogAPI` for pluralize is deprecated should be removed when the Search API adds mapping for `ENTITY_TYPE_GEO`
 * @returns {String} entityType plural
 *
 */
export const pluralize = (entityType, useSearchCatalogAPI) => {
  return useSearchCatalogAPI && entityType === ENTITY_TYPE_GEO ? 'geos' : entityTypesPluralMapping[entityType];
};

/**
 * Get Catalog Entity by entity type, id, storeId, and language via StubHub Catalog API.
 * Response will be cached for performance improvement.
 *
 * @param {String} entityType
 * @param {Number} entityId
 * @param {Number} storeId
 * @param {String} language
 * @param {Boolean} useSearchCatalogAPI
 * @param {object} queryParams
 * @param {number} timeout
 * @returns {Promise} Entity Object
 */
export function getEntity(entityType, entityId, storeId = 1, language, useSearchCatalogAPI, queryParams = {}, timeout) {
  /**
   * Headers
   *
   */
  const headers = {Authorization: process.env.REACT_APP_API_SECRET};
  if (language) {
    headers['Accept-Language'] = language;
  }

  /**
   * Query Params
   *
   */
  const defaultQuery = {
    mode: 'internal',
    shstore: storeId,
  };
  const query = {...defaultQuery, ...queryParams};

  return get({
    host: process.env.REACT_APP_API_HOST,

    /**
     * @TODO - `REMOVE_useSearchCatalogAPI` for pluralize is deprecated should be removed when the Search API adds mapping for `ENTITY_TYPE_GEO`
     *
     */
    path: `${useSearchCatalogAPI ? '/search' : ''}/catalog/${pluralize(entityType, useSearchCatalogAPI)}/v3${
      entityId ? `/${entityId}` : ''
    }/`, // StoreId is used just for catalog side logging purpose
    headers,
    query,
    json: true,
    cache: true,
    cacheMaxAge: CATALOG_CACHE_MAX_AGE,
    timeout,
  }).then((res = {}) => {
    const entity = pickEntityData(res, entityType);

    return entity;
  });
}
// Define which fields to be used in the Catalog API response. If value is null, will use all fields.
const pickEntries = {
  performers: [
    'id',
    'name',
    'status',
    'locale',
    'webURI',
    'seoURI',
    'seoURIs',
    'ancestors',
    'seoMeta',
    'images',
    'displayAttributes',
    'dynamicAttributes',
    'performerType',
    'teamColors',
  ],

  venues: [
    'id',
    'name',
    'status',
    'locale',
    'webURI',
    'seoURI',
    'seoURIs',
    'ancestors',
    'seoMeta',
    'latitude',
    'longitude',
    'address',
    'displayAttributes',
    'images',
    'dynamicAttributes',
  ],

  geographies: null, // Use all fields
  categories: null, // Use all fields
  groupings: null, // Use all fields

  events: [
    'id',
    'name',
    'status',
    'expiredInd',
    'locale',
    'currencyCode',
    'eventDateUTC',
    'eventDateLocal',
    'webURI',
    'seoURI',
    'seoURIs',
    'ancestors',
    'seoMeta',
    'images',
    'venue',
    'performers',
    'externalEventMappings',
  ], // TODO: need further confirmation
};

// Pick data fields from Catalog API response.
function pickEntityData(data, entityType) {
  if (pickEntries[entityType]) {
    return pick(data, pickEntries[entityType]);
  }

  return data;
}

/**
 * Method to generate a new array of objects by merge element without duplicates using id property
 * @param {Array} array1
 * @param {Array} array2
 * @returns new array of objects without duplicates
 */
export const mergeGCPArrays = (array1, array2) => {
  if (!array1?.length && !array2?.length) {
    return undefined;
  }
  const ids = array1?.map((elem) => elem.id).filter((elem) => elem !== undefined) || [];
  const array2WithoutDups = array2?.filter((elem) => !ids.includes(elem.id)) || [];
  const merged = [...(array1 || []), ...array2WithoutDups];

  return merged;
};
