const STATIC_ROUTES_ARRAY = [
  '/request-event',
  '/request-event-success',
  '/jobs',
  '/about-us',
  '/legal',
  '/legal-data-controller',
  '/notifications',
  '/overflow',
  '/partners',
  '/turkish_footbal',
  '/affiliates',
  '/contact-us',
  '/help',
  '/denuncias',
];

const STATIC_ROUTES = `(/[a-z]{2})?(${STATIC_ROUTES_ARRAY.join('\\b/?$|')})`;

const SPEEDY_ROUTES_ARRAY = [
  '.*/event/\\d+',
  '.*/category/\\d+',
  '.*/performer/\\d+',
  '.*/venue/\\d+',
  '.*/grouping/\\d+',
  '.*/geography/\\d+',
  '(/[a-z]{2})?/find/s\\b/?$',
];

const SPEEDY_ROUTES = `(/[a-z]{2})?(${SPEEDY_ROUTES_ARRAY.join('|')})`;

const EXTERNAL_APPS_ROUTES_ARRAY = ['/sell\\b/?', '/my/.+', '/checkout/.+', '/error\\b/?$', '/login\\b/?'];

const EXTERNAL_APPS_ROUTES = `(/[a-z]{2})?(${EXTERNAL_APPS_ROUTES_ARRAY.join('|')})`;

const UNSUPPORTED_ROUTES = `^(?!(${SPEEDY_ROUTES}|${EXTERNAL_APPS_ROUTES}|${STATIC_ROUTES})).*$`;

module.exports = {STATIC_ROUTES, SPEEDY_ROUTES, EXTERNAL_APPS_ROUTES, UNSUPPORTED_ROUTES};
