import {createSelector} from 'reselect';

import {daysBetweenDates} from '@stubhub/date-utils';
import {isPrimaryEvent, isDefined} from '@stubhub/general-utils';
import {getEventGenre} from '@stubhub/tracking-utils';

import {isRedirectModalEnabledSelector} from '../gsConfig/FF/selectors';

const eventSelector = (state) => (state && state.event) || {};
const venueSelector = createSelector(eventSelector, (eventInfo = {}) => eventInfo.venue || {});
const eventNameSelector = createSelector(eventSelector, (eventInfo = {}) => eventInfo.name || null);
const eventCountryCodeSelector = createSelector(venueSelector, (venue = {}) => venue.country || null);
const eventDateUTCSelector = createSelector(eventSelector, (eventInfo = {}) => eventInfo.eventDateUTC || null);
const daysToEventSelector = createSelector(eventDateUTCSelector, (date = {}) =>
  date ? +daysBetweenDates(Date.now(), date)?.toFixed(2) : 0,
);
const inventoryMarketSelector = createSelector(eventSelector, (eventInfo = {}) =>
  isDefined(eventInfo.externalSourceId) ? (isPrimaryEvent(eventInfo.externalSourceId) ? 'primary' : 'secondary') : null,
);
const ancestorsSelector = createSelector(eventSelector, (eventInfo = {}) => eventInfo.ancestors || {});
const categoriesSelector = createSelector(ancestorsSelector, (eventInfo = {}) => eventInfo.categories || []);
const firstPerformerSelector = createSelector(eventSelector, (eventInfo = {}) => eventInfo.firstPerformer || {});
const groupingsSelector = createSelector(ancestorsSelector, (eventInfo = {}) => eventInfo.groupings || []);
const performersSelector = createSelector(ancestorsSelector, (eventInfo = {}) => eventInfo.performers || []);
const eventInfoLoadingSelector = createSelector(eventSelector, (eventInfo = {}) =>
  eventInfo.loading !== undefined ? eventInfo.loading : true,
);
const genreSelector = createSelector(categoriesSelector, (categories = []) =>
  categories && categories.length > 0 ? getEventGenre(categories[0].id) : getEventGenre(-1),
);

const showRedirectionModalSelector = createSelector(
  isRedirectModalEnabledSelector,
  eventCountryCodeSelector,
  (isRedirectEnabled, venueCountryCode) =>
    isRedirectEnabled && !!venueCountryCode && venueCountryCode.toUpperCase() !== 'GB',
);

export {
  eventSelector,
  eventNameSelector,
  eventCountryCodeSelector,
  eventInfoLoadingSelector,
  daysToEventSelector,
  inventoryMarketSelector,
  genreSelector,
  categoriesSelector,
  firstPerformerSelector,
  groupingsSelector,
  performersSelector,
  showRedirectionModalSelector,
};
