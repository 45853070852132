/* istanbul ignore file */

/**
 * This is a Bullet App convention file.
 * DON'T RENAME OR MOVE TO OTHER FOLDERS.
 *
 * Please only export a default method e.g. called `getRoutes`,
 * which returns an array of `Route` (from react-router-dom v4.x)
 */

import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

import Spinner from '@stubhub/react-spinner';

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ './home'),
  loading: Spinner,
});

const Search = Loadable({
  loader: () => import(/* webpackChunkName: "search" */ './search'),
  loading: Spinner,
});

const Entity = Loadable({
  loader: () => import(/* webpackChunkName: "entity" */ './entity'),
  loading: Spinner,
});

export default function getRoutes({currentLocale, defaultLocale}) {
  const lang = currentLocale !== defaultLocale ? currentLocale.substr(0, 2) : '';
  const langPath = lang ? `/${lang}` : '';
  const routes = [
    <Route exact path={langPath || '/'} component={Home} key="Home" />,
    <Route
      path={`/:seo?${langPath}/category/1`}
      render={(props) => <Home categoryId="1" {...props} />}
      key="concerts"
    />,
    <Route
      path={`/:seo?${langPath}/category/28`}
      render={(props) => <Home categoryId="28" {...props} />}
      key="sports"
    />,
    <Route
      path={`/:seo?${langPath}/category/174`}
      render={(props) => <Home categoryId="174" {...props} />}
      key="theaters"
    />,
    <Route path="/:lang?/find/s" component={Search} key="search" />,
  ];
  routes.push(
    <Route
      path={`/:seo?${langPath}/performer/:entityId`}
      render={(props) => <Entity entityType="performer" {...props.match.params} {...props} />}
      key="performer"
    />,
  );
  routes.push(
    <Route
      path={`/:seo?${langPath}/venue/:entityId`}
      render={(props) => <Entity entityType="venue" {...props.match.params} {...props} />}
      key="venue"
    />,
  );
  routes.push(
    <Route
      path={`/:seo?${langPath}/grouping/:entityId`}
      render={(props) => <Entity entityType="grouping" {...props.match.params} {...props} />}
      key="grouping"
    />,
  );
  routes.push(
    <Route
      path={`/:seo?${langPath}/category/:entityId`}
      render={(props) => <Entity entityType="category" {...props.match.params} {...props} />}
      key="category"
    />,
  );
  routes.push(
    <Route
      path={`/:seo?${langPath}/geography/:entityId`}
      render={(props) => <Entity entityType="geography" {...props.match.params} {...props} />}
      key="geography"
    />,
  );

  return routes;
}
