export const SITE_RADIUS = 50;
export const DEFAULT_RADIUS = 200;

/**
 * For now, this mimics the DB mapping of store ID to the search
 * radius, so that all the stores work properly.
 *
 * TODO: When we have the infrastructure for this, we can get rid of this function.
 */
export function storeToSearchRadiusMapping(shstoreId) {
  switch (shstoreId) {
    /* eslint-disable no-magic-numbers */
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
      /* eslint-enable no-magic-numbers */
      return SITE_RADIUS;
    default:
      // Default search radius is 200
      return DEFAULT_RADIUS;
  }
}
