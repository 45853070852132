import PropTypes from 'prop-types';

import {useSubscriber} from '@stubhub/pubsub-utils';
import {connect} from '@stubhub/react-store-provider';

import controller from './controller';
import {
  TICKET_EVENTS,
  SEARCH_EVENTS,
  ENTITY_EVENTS,
  HOME_EVENTS,
  EVENT_PAGE,
  NOT_FOUND_EVENTS,
  EXIT_INTENT_EVENTS,
  CHATBOT_EVENTS,
} from './track-enum';
import {
  screenView,
  entityScreenView,
  entityViewItem,
  entityViewItemList,
  getUserGUID,
  listingViewItem,
  eventViewItem,
  getViewItemSharedParameters,
  subscribeNewsletter,
  abandonViewport,
  noFullfilmentError,
  chatbot,
} from './track-utils';

const TrackListener = (props, {track, cookies}) => {
  const homeTracking = {
    [HOME_EVENTS.LOADED]: () => {
      const {
        storeId,
        locale,
        storeCountry,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userCountry,
        userCity,
        userCoords,
      } = props;
      const userGUID = getUserGUID(cookies);
      screenView(track, {
        screenName: 'home',
        storeId,
        storeCountry,
        locale,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userGUID,
        userCountry,
        userCity,
        userCoords,
      });
    },
    [HOME_EVENTS.CATEGORY]: ({categoryId, entityType, data = {}}) => {
      const {name} = data;
      const fakeEntity = {entityType, id: categoryId, name};
      entityScreenView(track, props, cookies, entityType);
      entityViewItem(track, fakeEntity);
    },
    [HOME_EVENTS.NEWSLETTER]: ({email}) => {
      subscribeNewsletter(track, email);
    },
  };

  const entityTracking = {
    [ENTITY_EVENTS.LOADED]: () => {
      const {entity = {}, entityGCP = {}} = props;
      entityScreenView(track, props, cookies, entity.entityType);
      entityViewItem(track, entity, entityGCP);
    },
  };

  const searchTracking = {
    [SEARCH_EVENTS.LOADED]: () => {
      const {
        storeId,
        locale,
        storeCountry,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userCountry,
        userCity,
        userCoords,
      } = props;
      const userGUID = getUserGUID(cookies);
      screenView(track, {
        screenName: 'search',
        storeId,
        storeCountry,
        locale,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userGUID,
        userCountry,
        userCity,
        userCoords,
      });
    },
    [SEARCH_EVENTS.TERM]: () => {
      const {searchbar: {term, response: {totalPerformersFound, totalEventsFound, totalVenuesFound} = {}} = {}} = props;
      entityViewItemList(track, {
        itemListId: 'search_bar_results',
        filterKeywords: term,
        performerListQty: totalPerformersFound,
        eventListQty: totalEventsFound,
        venueListQty: totalVenuesFound,
      });
    },
  };

  const ticketTracking = {
    [TICKET_EVENTS.FULLFILMENT_ERROR]: () => {
      noFullfilmentError(track);
    },
  };

  const eventTracking = {
    [EVENT_PAGE.LOADED]: () => {
      const {
        storeId,
        locale,
        storeCountry,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userCountry,
        userCity,
        userCoords,
      } = props;
      const userGUID = getUserGUID(cookies);
      screenView(track, {
        screenName: 'event',
        storeId,
        storeCountry,
        locale,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userGUID,
        userCountry,
        userCity,
        userCoords,
      });
    },
    [EVENT_PAGE.LISTINGS]: ({listingId, price = {}, byoQuantity}) => {
      const {
        eventId,
        name,
        daysToEvent,
        eventCountry,
        firstPerformer = {},
        genre,
        inventoryMarket,
        ancestors,
        eventStructureData,
        eventDateUTC,
        dynamicAttributes,
        eventGCP,
      } = props;
      const {amount: priceAmount, currency} = price;
      const {firstPerformerId, ancestorsTree, pictureUrl, eventDateTs, eventOnSaleDateTs, geoId, geoTree} =
        getViewItemSharedParameters({
          firstPerformer,
          eventStructureData,
          eventDateUTC,
          dynamicAttributes,
          ancestors,
          eventGCP,
        });
      listingViewItem(track, {
        listingId,
        price: priceAmount,
        currency,
        quantity: byoQuantity,
        eventId,
        days_to_event: daysToEvent,
        genre,
        inventory_market: inventoryMarket,
        firstPerformerId,
        gcp_tree: ancestorsTree,
        country_code: eventCountry,
        geo_id: geoId,
        geo_tree: geoTree,
        picture_url: pictureUrl,
        event_date_ts: eventDateTs,
        event_onsale_ts: eventOnSaleDateTs,
        eventName: name,
      });
    },
    [EVENT_PAGE.EVENT_DETAILS]: () => {
      const {
        eventId,
        name,
        daysToEvent,
        eventCountry,
        firstPerformer = {},
        genre,
        inventoryMarket,
        ancestors,
        eventStructureData,
        eventDateUTC,
        dynamicAttributes,
        eventGCP,
        quantities,
      } = props;
      const {firstPerformerId, ancestorsTree, pictureUrl, eventDateTs, eventOnSaleDateTs, geoId, geoTree} =
        getViewItemSharedParameters({
          firstPerformer,
          eventStructureData,
          eventDateUTC,
          dynamicAttributes,
          ancestors,
          eventGCP,
        });
      const {totalTickets, totalListings} = quantities;
      eventViewItem(track, {
        eventId,
        days_to_event: daysToEvent,
        name,
        genre,
        inventory_market: inventoryMarket,
        parent_gcp_id: firstPerformerId,
        gcp_tree: ancestorsTree,
        country_code: eventCountry,
        geo_id: geoId,
        geo_tree: geoTree,
        picture_url: pictureUrl,
        event_date_ts: eventDateTs,
        event_onsale_ts: eventOnSaleDateTs,
        ticket_qty: totalTickets,
        listing_qty: totalListings,
      });
    },
  };

  const notFoundTracking = {
    [NOT_FOUND_EVENTS.LOADED]: () => {
      const {storeId, locale, storeCountry} = props;
      screenView(track, {
        screenName: 'not_found_page',
        storeId,
        storeCountry,
        locale,
      });
    },
  };

  const exitIntentTracking = {
    [EXIT_INTENT_EVENTS.LOADED]: () => {
      const {storeId, locale, storeCountry, isAdvisoryCurrencyEnabled, advisoryCurrency} = props;
      const userGUID = getUserGUID(cookies);
      abandonViewport(track, {
        storeId,
        storeCountry,
        locale,
        isAdvisoryCurrencyEnabled,
        advisoryCurrency,
        userGUID,
      });
    },
  };

  const chatbotTracking = {
    [CHATBOT_EVENTS.OPEN]: () => {
      const {storeId, locale, storeCountry} = props;
      chatbot(track, {
        storeId,
        storeCountry,
        locale,
        status: 'open',
      });
    },
    [CHATBOT_EVENTS.CLOSE]: () => {
      const {storeId, locale, storeCountry} = props;
      chatbot(track, {
        storeId,
        storeCountry,
        locale,
        status: 'close',
      });
    },
  };

  useSubscriber(HOME_EVENTS.MAIN, homeTracking);
  useSubscriber(ENTITY_EVENTS.MAIN, entityTracking);
  useSubscriber(SEARCH_EVENTS.MAIN, searchTracking);
  useSubscriber(TICKET_EVENTS.MAIN, ticketTracking);
  useSubscriber(EVENT_PAGE.MAIN, eventTracking);
  useSubscriber(NOT_FOUND_EVENTS.MAIN, notFoundTracking);
  useSubscriber(EXIT_INTENT_EVENTS.MAIN, exitIntentTracking);
  useSubscriber(CHATBOT_EVENTS.MAIN, chatbotTracking);

  return null;
};

TrackListener.contextTypes = {
  track: PropTypes.object,
  cookies: PropTypes.object,
};

TrackListener.propTypes = {
  /** Event ancestors */
  ancestors: PropTypes.object,

  /** Current advisory currency */
  advisoryCurrency: PropTypes.string,

  /** Number of days until the event */
  daysToEvent: PropTypes.number,

  /** Event dynamic attributes */
  dynamicAttributes: PropTypes.array,

  /** Entity data */
  entity: PropTypes.object,

  /** Event country code */
  eventCountry: PropTypes.string,

  /** Event date in UTC */
  eventDateUTC: PropTypes.string,

  /** The event id */
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Event structured data */
  eventStructureData: PropTypes.object,

  /** Event first associated performer */
  firstPerformer: PropTypes.object,

  /** Event genre */
  genre: PropTypes.string,

  /** Inventory market */
  inventoryMarket: PropTypes.oneOf(['primary', 'secondary']),

  /** Is advisory currency enabled? */
  isAdvisoryCurrencyEnabled: PropTypes.bool,

  /** Current locale */
  locale: PropTypes.string,

  /** Event name */
  name: PropTypes.string,

  /** Search information */
  searchbar: PropTypes.shape({
    /** Term searched */
    term: PropTypes.string,

    /** Search response */
    response: PropTypes.object,
  }),

  /** Current store id */
  storeId: PropTypes.number,

  /** Store default country */
  storeCountry: PropTypes.string,

  /** User Ip country */
  userCountry: PropTypes.string,

  /** User Ip city */
  userCity: PropTypes.string,

  /** User latitude and longitude coords */
  userCoords: PropTypes.string,

  /** Object generated by merge of originial event ancestors and categories, groupings and performers */
  eventGCP: PropTypes.object,

  /** Object generated by merge of originial entity ancestors and categories, groupings and performers */
  entityGCP: PropTypes.object,

  /** Object containing total tickets and listings */
  quantities: PropTypes.object,
};

export default connect(controller)(TrackListener);
