import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {publish} from '@stubhub/pubsub-utils';
import VirtualAssistantLazyLoad from '@stubhub/react-lazy-virtual-assistant';

import {CHATBOT_EVENTS} from '../../tracking/track-enum';
import {fetchContact} from '../../utils/api';

const Chatbot = ({locale}, {cookies, logger}) => {
  const [customerName, setCustomerName] = useState();
  const userGUID = cookies?.get('session_userGUID');

  useEffect(() => {
    if (userGUID) {
      const getContact = async () => {
        try {
          const {
            contactsV2: {contactV2: contacts},
          } = await fetchContact(userGUID, logger);
          const {
            name: {firstName},
          } = contacts.find(({defaultInd}) => defaultInd === 'Y');
          setCustomerName(firstName);
        } catch (error) {
          // logged by restmethod.get in fetchContact
        }
      };
      getContact();
    }
  }, [userGUID, logger]);

  const projectID = process.env.REACT_APP_VOICE_FLOW_PROJECT_ID;

  const handleChatbotOpen = () => {
    publish(CHATBOT_EVENTS.OPEN);
  };

  const handleChatbotClose = () => {
    publish(CHATBOT_EVENTS.CLOSE);
  };

  return (
    <VirtualAssistantLazyLoad
      type="VOICE_FLOW"
      projectID={projectID}
      userName={customerName}
      GUID={userGUID}
      locale={locale}
      loadAutomatically={false}
      onOpen={handleChatbotOpen}
      onClose={handleChatbotClose}
    />
  );
};

Chatbot.propTypes = {
  locale: PropTypes.string,
};

Chatbot.contextTypes = {
  cookies: PropTypes.object,
  logger: PropTypes.object,
};

export default Chatbot;
